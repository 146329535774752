/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Fonts
 **/
$primary-font: 'Nunito Sans', sans-serif;

/**
 * Colors
 **/

$primary-color: #D93F34;
$secondary-color: #00A5D4;

$white: #FFFFFF;
$light-gray: #E8E7E7;
$black: #121212;