/**
 * Footer
 **/

#footer{
	background-color: $black;
	padding-top: 2rem;
	padding-bottom: 2rem;
	margin-top: 0;
	margin-bottom: 0;

	h5{
		color: $white;
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 1.25rem;
	}

	ul{
		padding-left: 0;
		list-style-type: none;

		li,
		li a{
			color: $white;
		}

		a{
			transition: all .3s ease-in-out;

			&:hover{
				color: $secondary-color;
				text-decoration: none;
			}
		}
	}

}

.menu-footer-2-container{
	margin-top: 2.55rem;	
}

.secondary-footer{
	background-color: $secondary-color;
	margin-bottom: 0;

	p,
	a{
		color: $white;
		font-size: 12px;
	}

	p{
		padding-top: 5px;
		padding-bottom: 5px;
	}
}

.mt-footer{
	margin-top: 2.5rem;
}