/**
 * Pagination
 **/

.tool-pagination{
	margin-left: 5px;
}

.pagination-wrap{
	float: left;
	margin: 10px 0;
	
	@include media-breakpoint-only(xs) {
		position: relative;
		right: 15px;
	}
	
	ul{
		padding: 0;
		margin: 0;
		display: inline-block;

		li{
			list-style-type: none;
			display: inline-block;
			border: 1px solid $black;
			height: 28px;
			width: 27px;
			margin: 0 1px;
			position: relative;
			transition: all .3s ease-in-out;

			a, span{
				color: $black;
				text-decoration: none;
				transition: all .3s ease-in-out;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				font-size: inherit;
				text-decoration: none !important;
				padding: 0;
				line-height: inherit;
				border: none;
				margin: 0;
			}

			&:hover{
				border: 1px solid $primary-color;

				a{
					color: $primary-color;
				}
			}
		}

		.current{
			background-color: $black;

			span{
				font-weight: 600;
				color: $white;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
			}

			&:hover{
				border: 1px solid $black;
			}
		}
	}

	.next, .prev{
		position: relative;
		bottom: 8px;
		color: $black;
		text-decoration: none;
		transition: all .3s ease-in-out;

		&:hover{
			color: $black;
		}
	}
}
