/**
 * Newsletter
 **/

.newsletter{
	margin-bottom: 0;

	h3{
		font-size: 24px;
	}

	p{
		margin: 0 auto;
 		max-width: 400px;
	}
}

#wpcf7-f69-o1{
	width: 100%;
	margin-top: 3rem;
}

form{
	input{
		background-color: $black;
		border: 0;
		color: $white;
		box-shadow: none;
		height: 44px;
		width: 100%;
		padding-left: 15px;
	}

	input[type="submit"]{
		background-color: $primary-color;
		color: $white;
		padding-left: 0;
	}
}