/**
 * Subpage
 **/

.subpage{
	h1{
		font-weight: 700;
		margin-bottom: 4rem;
	}
}

/**
 * Nieuws
 **/
.news{
	background-color: $light-gray;

	.news-item{
	    margin-bottom: 1rem;
	    padding: 5px;

		img{
			width: 100%;
		}

		h3{
			font-size: 18px;
			padding: 15px;
		}

		&__inner{
			background-color: $white;
			width: 100%;
			height: 100%;
		}

		&__date{
			font-weight: 700;

			span{
				padding-left: 10px;
			}
		}

		&__date,
		&__title{
			padding-top: 5px;
		}

		p{
			padding-left: 15px;
		}
	}
}


/**
 * Agenda
 **/

 .agenda{

 	.agenda-item{
	    background-color: $white;
	    margin-bottom: 1rem;
	    border: 1px solid $light-gray;
	    padding: 5px;

		&__date,
		&__title{
			padding-top: 5px;
		}

		&__date{
			font-weight: 700;

			@include media-breakpoint-down(sm) {
				margin-left: 5px;
			}

			span{
				padding-left: 10px;
			}
		}

		&__title{
			@include media-breakpoint-down(sm) {
				margin-bottom: 10px;
				margin-left: 15px;
			}
		}

		p{
			padding-left: 15px;
			margin-bottom: 0;
		}

		a{
			@include media-breakpoint-down(sm) {
				margin-bottom: 10px;
			}
		}
	}
 }


/**
 * Single page
 **/

.single-page{

}

.event-date{
	font-weight: 700;
	color: $secondary-color;
}