/**
 * Mobile only
 **/

@include media-breakpoint-down(md) {
	#menu-entries{
		display: none !important;
	
		&.collapse{
			display: none !important;
		}

		&.collapse.in{
			display: inherit !important;
		}
	}
}


#nav-main{
	margin-bottom: 0;

	&.bg-light{
		background-color: $white !important;
	}

	.navbar-brand{
		height: auto;
	}

	.navbar-nav{
		float: right;
	}

	.nav-link{
		color: $black;
		font-size: 16px;
		transition: all .3s ease-in-out;
	}
}