/**
 * Body
 **/

.block{
	margin-top: 4rem;
	margin-bottom: 4rem;
}

.block-p{
	margin-top: 4rem;
	margin-bottom: 4rem;
	padding-top: 4rem;
	padding-bottom: 4rem;
}