/**
 * Typography
 **/

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body{
	font-family: $primary-font;
}

a:not(.btn){
	color: $primary-color;
	transition: all .3s ease-in-out;

	&:hover{
		color: $secondary-color;
		text-decoration: none;
	}
}

p{
	font-size: 16px;
	line-height: 25px;
}

.single-page,
.subpage{
	h2{
		margin-bottom: 1.5rem;
	}
}


/**
 * Buttons
 **/

.btn{
	color: $white;
	border-radius: 0 !important;

	&--black{
		background-color: $black;

		&:hover{
			background-color: $primary-color;
		}
	}


	&--primary{
		background-color: $primary-color;

		&:hover{
			background-color: $primary-color;
		}
	}
}

