/**
 * Header
 **/

.header{
	.header-image{
		height: 450px;
		background-size: cover;		

		h1{
			color: $white;
			margin-top: 4rem;
			font-size: 52px;
			line-height: 52px;
			font-weight: 700;
			max-width: 500px;

			text-shadow: 0 2px 40px rgba(0,0,0,0.20);

			@include media-breakpoint-down(sm) {
				font-size: 42px;
			}
		}
	}

	&--sub{
		.header-image{
			height: 300px;
		}
	}
}