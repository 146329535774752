/**
 * Front
 **/
.intro-blocks{
	margin-top: -70px;

	@include media-breakpoint-up(md) {
		margin-top: -250px;
	}	
}

.table-block{
	&__inner{
		border: 1px solid $light-gray;
		background-color: $white;
		padding: 35px;
		position: relative;
		padding-bottom: 50px;		
	}

	h2{
		font-weight: 700;
		font-size: 22px;
		margin-bottom: 1.5rem;
	}

	.table-item{
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid $light-gray;

		&:last-of-type{
			border-bottom: 0;
		}

		a{
			color: $black;

			&:hover{
				color: $primary-color;
			}
		}
	}

	.label{
		background-color: $primary-color;
		border-radius: 16px;
		color: $white;
		display: inline-block;
		padding: 4px;
		padding-left: 25px;
		padding-right: 25px;
		font-weight: 700;
		font-size: 11px;

		@include media-breakpoint-up(md) {
			width: 100%;
		}

		&--primary{
			background-color: $primary-color;
		}

		&--secondary{
			background-color: $secondary-color;
		}
	}

	.btn{
		position: absolute;
		right: 0;
		bottom: 0;
		margin-bottom: 0;
		border-radius: 0;
		padding: 10px 20px;
	}
}

.home-content{
	h2{
		margin-top: 3rem;
	}

	img{
		width: 100%;
	}
}

.sponsoren{
	h3{
		margin-bottom: 1.5rem;
	}

	.sponsor__item-inner{
		border:	1px solid $light-gray;
	    border-radius: 22px;
	    overflow: hidden;
	    margin-bottom: 10px;
	    width: 100%;
	    height: 150px;
	    padding: 2.5rem;
	    position: relative;		
	}

	img{
	    margin: 0 auto;
	    display: block;
	    width: auto !important;
	    -webkit-transform: translateY(-50%);
	    transform: translateY(-50%);
	    position: relative;
	    top: 50%;		
	}
}
